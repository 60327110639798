/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';

export default class FinStandaloneBaseChannelsRoute extends Route {
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;

  resetController(controller: any, isExiting: boolean) {
    if (isExiting) {
      this.knowledgeHubDrawerEditorService.removeEmberStickyQueryParams(controller);
    }
  }
}
