/* RESPONSIBLE TEAM: team-help-desk-experience */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SettingsIndexRoute extends Route {
  @service intl;

  get titleToken() {
    return this.intl.t('new-settings.page-title');
  }
}
