/* RESPONSIBLE TEAM: team-standalone */
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { hash } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type SdkAppService from 'embercom/services/sdk-app-service';
import type SdkApp from 'embercom/models/sdk-app';
import type ArrayProxy from '@ember/array/proxy';
import { default as BaseChannelRoute } from 'embercom/routes/apps/app/standalone/channels/base-channels';

export default class FinStandaloneIntercomMessengerRoute extends BaseChannelRoute {
  @service declare appService: { app: { id: string } };
  @service declare sdkAppService: SdkAppService;
  @service declare store: Store;

  get app() {
    return this.appService.app;
  }

  async model() {
    let sdkApps = this.store.peekAll('sdk-app') as ArrayProxy<SdkApp>;
    let iosSdkApp = sdkApps.find((sdkApp) => sdkApp.platform === 'iOS');
    let androidSdkApp = sdkApps.find((sdkApp) => sdkApp.platform === 'Android');

    if (iosSdkApp && androidSdkApp) {
      this.sdkAppService.initWithSdkApps(iosSdkApp, androidSdkApp);
    }

    return hash({
      sdkApps:
        iosSdkApp && androidSdkApp
          ? [iosSdkApp, androidSdkApp]
          : taskFor(this.sdkAppService.loadSdkApps).perform(),
      operatorIdentity:
        this.store.peekRecord('operator-identity', '1') ||
        this.store.findRecord('operator-identity', '1'),
      messengerSettings:
        this.store.peekRecord('messenger-settings/all', this.app.id) ||
        this.store.findRecord('messenger-settings/all', this.app.id),
    });
  }
}
