/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { RESOLUTION_BOT_REQUIRED_PERMISSION } from 'embercom/lib/operator/resolution-bot/constants';
import type Transition from '@ember/routing/transition';
import type Store from '@ember-data/store';
import type AnswerFollowOnCreationService from 'embercom/services/answer-follow-on-creation-service';

export default class AutomationFinAIAgentCustomAnswersNewRoute extends Route {
  @service declare store: Store;
  @service declare intercomEventService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare answerFollowOnCreationService: AnswerFollowOnCreationService;
  @service declare appService: $TSFixMe;
  @service declare automationNavCounts: $TSFixMe;

  async beforeModel(transition: Transition) {
    let queryParams = transition.to.queryParams;
    this.intercomEventService.trackEvent('answer-bot-interacted');

    this.permissionsService.ensurePermissionWhenTransitioning(
      RESOLUTION_BOT_REQUIRED_PERMISSION,
      transition,
    );

    let answer = await this._createAnswer(queryParams).save();
    await this.automationNavCounts.loadCustomAnswerCounts();

    if (queryParams.suggestionId) {
      this._applySuggestedCluster(
        answer,
        queryParams.suggestionId,
        queryParams.clusterPosition as $TSFixMe,
      );
    } else if (queryParams.clusterId && queryParams.exampleIds) {
      this._applyClusterExample(answer, queryParams.clusterId, queryParams.exampleIds);
    } else if (queryParams.exampleText) {
      answer.exampleQuestions.createFragment({
        text: queryParams.exampleText,
        addedFrom: queryParams.exampleSource,
        identifier: queryParams.exampleId,
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        answer_id: answer.get('id'),
        place: 'answers_index_page',
        action: 'clicked',
        object: 'new_answer',
      });
    }

    if (this.answerFollowOnCreationService.questionsSelected) {
      Object.keys(this.answerFollowOnCreationService.questionsSelected).forEach((key) => {
        let question = this.answerFollowOnCreationService.questionsSelected[key].text;
        let cluster = this.store.createRecord('custom-answers/suggested-answer-cluster', {
          archetype: question,
        });
        answer.applySuggestedCluster(cluster);
      });

      this.answerFollowOnCreationService.reset();
    }

    this.replaceWith(`${this.appService.app.answersRoute}.answer.edit`, answer, {
      queryParams,
    });
  }

  _createAnswer({ language }: $TSFixMe) {
    return this.store.createRecord('custom-answers/custom-answer', {
      appId: this.appService.app.id,
      languageCode: language,
    });
  }

  _applySuggestedCluster(answer: $TSFixMe, suggestionId: $TSFixMe, clusterPosition: number) {
    let cluster = this.store.peekRecord('custom-answers/suggested-answer-cluster', suggestionId);
    this.intercomEventService.trackAnalyticsEvent({
      answer_id: answer.get('id'),
      place: 'answers_index_page',
      action: 'clicked',
      object: 'create_new_answer',
      context: 'from_clusters',
      keywords: cluster.get('archetype'),
      tile_position: clusterPosition < 0 ? null : clusterPosition + 1,
    });
    answer.applySuggestedCluster(cluster);
  }

  _applyClusterExample(answer: $TSFixMe, clusterId: $TSFixMe, exampleIds: $TSFixMe) {
    let cluster = this.store.peekRecord('custom-answers/cluster', clusterId);
    this.intercomEventService.trackAnalyticsEvent({
      answer_id: answer.get('id'),
      place: 'answers_suggestions_page',
      action: 'clicked',
      object: 'create_new_answer',
      context: 'from_clusters',
      archetype: cluster.archetype.text,
    });

    let examples: $TSFixMe[] = [];
    exampleIds.split(',').forEach(function (exampleId: $TSFixMe) {
      let example = cluster.examples.findBy('id', exampleId);
      examples.push(example);
    });
    answer.applyExample(cluster, examples);
  }
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.custom-answers.new',
  };
}
