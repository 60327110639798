/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';

export default class AutomationFinAIAgentCustomAnswersAnswerShowRoute extends Route {
  @service declare intercomEventService: $TSFixMe;

  answer: $TSFixMe;

  setupController(controller: $TSFixMe, model: $TSFixMe, transition: Transition) {
    super.setupController(controller, model, transition);
    this.answer = model;
  }

  resetController(controller: $TSFixMe) {
    controller.eventType = null;
  }

  @action
  didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: this.answer,
      from_template: this.answer.fromTemplate,
    });
  }

  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.custom-answers.answer.show',
  };

  queryParams = { eventType: { refreshModel: true } };
}
