/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import ENV from 'embercom/config/environment';

export default class AppsAppOutboundSeriesSeriesRoute extends Route {
  @service store;
  @service appService;
  @service navbarCollapsingService;
  @service notificationsService;
  @service intercomEventService;
  @service intercomConfirmService;
  @service reportingMetrics;

  analytics = {
    section: 'outbound',
    place: 'series',
  };

  async beforeModel() {
    return await Promise.all([
      this.store.findAll('conversation-attributes/descriptor'),
      this.store.findAll('messenger-app'),
      this.appService.app.loadEmailDomains(),
      this.reportingMetrics.setup.perform(),
      this.store.findAll('workflow-connector/insertable-action'),
    ]);
  }

  async model(params) {
    return await this.store.findRecord('series/series', params.id);
  }

  activate() {
    this.intercomEventService.trackEvent('series-viewed-editor');

    if (ENV.environment !== 'test' && !this.hasSetupBeforeUnload) {
      this.hasSetupBeforeUnload = true;
      window.onbeforeunload = (e) => {
        let series = this.controller.model;

        if (series.hasUnsavedChanges) {
          let text = "You'll lose your changes if you don't save first.";
          e.returnValue = text;
          return text;
        }
      };
    }
  }

  deactivate() {
    if (this.navbarCollapsingService.collapsed !== false) {
      this.navbarCollapsingService.set('collapsed', false);
    }
    window.onbeforeunload = undefined;
    this.hasSetupBeforeUnload = false;
    this.currentModel.unloadRecord();
  }

  @action didTransition() {
    // After the controller has loaded, unset the Initial Node query param if it was provided.
    // This will remove it from the URL.
    schedule('afterRender', this, () => {
      this.controller.nodeId = null;
    });
  }

  @action
  async willTransition(transition) {
    let series = this.controller.model;
    if (!transition.data.forceTransition && series.hasUnsavedChanges) {
      transition.abort();
      let confirmOptions = {
        title: 'Close without saving?',
        primaryButtonType: 'primary-destructive',
        confirmButtonText: 'Close without saving',
        cancelButtonText: 'Keep editing',
        body: "You'll lose your changes if you don't save first.",
      };
      let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
      if (!isConfirmed) {
        return;
      }
      transition.data.forceTransition = true;
      transition.retry();
    }
  }

  @action error(response) {
    this.notificationsService.notifyResponseError(response, {
      404: `We couldn't find that Series`,
      403: `You don't have permission to do that. Ask an admin on your team for help.`,
      default: `Something went wrong on our side, please try again and reach out to our support team if the problem persists.`,
    });
    this.transitionTo('apps.app.outbound.series');
  }
}
