/* RESPONSIBLE TEAM: team-ai-agent */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export default class ResolutionBotBehaviorsEditRoute extends IntercomRoute {
  @service declare router: RouterService;

  analytics = {
    section: 'resolution-bot',
    place: 'behaviors-edit',
  };

  redirect(model: $TSFixMe) {
    this.router.replaceWith('apps.app.automation.fin-ai-agent.profiles.edit', model.id);
  }
}
