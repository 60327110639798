/* RESPONSIBLE TEAM: team-ai-insights */
import InsightsCurateManage from 'embercom/routes/apps/app/automation/insights-curate-manage';
import type Transition from '@ember/routing/-private/transition';

export default class FinStandaloneInsightsCurateManage extends InsightsCurateManage {
  analytics = {
    section: 'standalone',
    place: 'insights-curate-manage',
  };

  templateName = 'apps/app/automation/insights-curate-manage';

  activate() {}

  async beforeModel(transition: Transition) {
    if (!this.appService.app.canSeeAiInsightsTopicsV1) {
      this.router.transitionTo('apps.app.standalone.setup');
      return;
    }
    this.permissionsService.ensurePermissionWhenTransitioning('can_access_reporting', transition);
  }
}
