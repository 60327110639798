/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export default class AutomationFinAIAgentContentRoute extends Route {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.content',
  };
  @service declare router: RouterService;

  beforeModel() {
    this.router.transitionTo('apps.app.knowledge-hub.all-content');
  }
}
