/* RESPONSIBLE TEAM: team-knowledge-interop */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type IntlService from 'embercom/services/intl';
import type ContentImportService from 'embercom/services/content-import-service';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type RouterService from '@ember/routing/router-service';
import { REDIRECTION_TO_KH_CONTENT_PAGE_KEY } from 'embercom/routes/apps/app/knowledge-hub/index';

export default class KnowledgeHubRoute extends Route {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare helpCenterService: $TSFixMe;
  @service declare contentImportService: ContentImportService;
  @service declare conversationExtractionService: ConversationExtractionService;
  @service declare finStandaloneService: FinStandaloneService;

  get titleToken(): string {
    return this.intl.t('knowledge-hub.title');
  }

  get app() {
    return this.appService.app;
  }

  // we call checklist attributes for:
  // 1) redirection purposes (ref: https://github.com/intercom/embercom/pull/90344)
  // 2) on overview page, for non-main tabs (customers need to click tabs first to see the checklists)
  //
  // the call's only need to block rendering when it's the first visit for redirection [1]
  // but if key exists in local storage already then we can call on background
  // because it will only be used for the overview page's non-main tabs [2]
  get shouldFetchKnowledgeChecklistAttributesOnBackground() {
    return localStorage.getItem(REDIRECTION_TO_KH_CONTENT_PAGE_KEY) === 'true';
  }

  get shouldFetchConversationExtractionSettings() {
    return (
      !this.appService.app.canUseStandalone || this.appService.app.canUseExtractionExternalSources
    );
  }

  async beforeModel() {
    ConversationAttributeDescriptor.peekAllAndMaybeLoad();
    if (this.shouldFetchKnowledgeChecklistAttributesOnBackground) {
      this.knowledgeHubService.getOverviewChecklistAttributes();
    }

    let promises = [
      this.knowledgeHubService.loadKnowledgeTree(),
      this.contentImportService.fetchContentImportSources(),
      this.knowledgeHubService.fetchImportSources(),
      this.knowledgeHubService.fetchAvailableLocalesFromMessengerAndHelpCenter(),
      this.knowledgeHubService.fetchFoldersOnce(),
      this.knowledgeHubService.fetchArticleCollections(),
      ...(!this.shouldFetchKnowledgeChecklistAttributesOnBackground
        ? [this.knowledgeHubService.getOverviewChecklistAttributes()]
        : []),
      ...(this.shouldFetchConversationExtractionSettings
        ? [this.conversationExtractionService.fetchConversationExtractionSettings()]
        : []),
      ...(this.appService.app.canUseStandalone ? [this.finStandaloneService.initialize()] : []),
    ];

    await Promise.all(promises);
  }
}
