/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export default class ConfigureRoute extends Route {
  @service declare router: RouterService;
  redirect(): void {
    this.router.replaceWith('apps.app.automation.fin-ai-agent.setup');
  }
}
