/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import { readOnly } from '@ember/object/computed';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';
import {
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
} from 'embercom/lib/billing';
import $ from 'jquery';

export default Route.extend({
  purchaseAnalyticsService: service(),
  gtmService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  router: service(),
  earlyStageService: service(),
  queryParams: {
    solutionId: {
      replace: true,
    },
    partner: {
      replace: true,
    },
  },

  activate() {
    $('body').addClass('responsive');
  },

  deactivate() {
    $('body').removeClass('responsive');
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.set('solutionId', model.solutionId);
    controller.set('partner', model.partner);
    controller.hideWidgetIfAppropriate();
  },

  getQueryParamsFromTransition(transition) {
    return {
      solutionId: transition.to.queryParams.solution_id,
      partner: transition.to.queryParams.partner,
    };
  },

  async model(_params, transition) {
    // Fallback for any non early stage solution ids
    // This shouldn't be happening, but just in case
    // If partner param exists, set partnerSource and solutionId to early stage free
    // Otherwise, set partnerSource to null and solutionId to early stage solution
    let { solutionId, partner } = this.getQueryParamsFromTransition(transition);
    if (!this.earlyStageService.isEarlyStage(solutionId)) {
      if (partner) {
        this.earlyStageService.partnerSource = partner || true;
        solutionId = PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID;
      } else {
        this.earlyStageService.partnerSource = null;
        solutionId = PRICING_5_X_EARLY_STAGE_SOLUTION_ID;
      }
    }

    await this.earlyStageService.fetchEarlyStagePartners();
    return { solutionId, partner };
  },

  beforeModel(transition) {
    if (this.app.earlyStageApplicationRejected) {
      this.router.transitionTo('apps.app.teams-checkout');
    }

    let { solutionId, partner } = this.getQueryParamsFromTransition(transition);

    // Feels very strange that parts of the early stage application are being set based on the solutionId
    // and others are based on the this.earlyStageService.partnerSource
    if (this.earlyStageService.isEarlyStageFreeSolutionId(solutionId)) {
      this.earlyStageService.partnerSource = partner || true;
    } else {
      this.earlyStageService.partnerSource = null;
    }

    if (this.app.validEarlyStageApplicant) {
      if (this.app.hasNoActiveSubscription) {
        this.router.transitionTo('apps.app.teams-checkout');
      } else {
        this.router.transitionTo('apps.app');
      }
    }
  },

  actions: {
    didTransition() {
      let controller = this.controllerFor('apps.app.teams-checkout.early-stage-application');
      let event = {
        action: 'viewed',
        context: 'usecase_signup_flow',
        place: 'early_stage_application',
        object: 'early_stage_application',
        solutionId: controller.solutionId,
        gclid: controller.gclid,
        emailSubmissionId: controller.emailSubmissionId,
        mobile: controller.mobileDevice,
        locale: this.app.currentAdmin.locale,
      };

      this.purchaseAnalyticsService.trackPageView(event);
    },
  },

  afterModel(model) {
    // This removed the params from the query after it has happened
    // because I'm swapping the solutionId and partner based as a fallback
    // I thought it would be better to not show them at all in the query params
    this.transitionTo({
      queryParams: {
        solutionId: model.solutionId,
        ...(model.partner && { partner: model.partner }),
      },
    });
    this.gtmService.setupMarketo(MARKETO_FORM_IDS.earlyStage);
  },
});
