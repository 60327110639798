/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { type TaskInstance } from 'ember-concurrency';

export default class IntegrationRoute extends Route {
  @service declare appService: { app: { hasStandalonePlatform(platform: string): boolean } };
  @service declare finStandaloneService: FinStandaloneService;

  async beforeModel() {
    let promises: TaskInstance<void>[] = [];

    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      if (this.finStandaloneService.isLoadingZendeskConfig) {
        promises.push(taskFor(this.finStandaloneService.loadZendeskConfig).lastRunning!);
      } else {
        promises.push(taskFor(this.finStandaloneService.loadZendeskConfig).perform());
      }
    }

    if (this.appService.app.hasStandalonePlatform('salesforce')) {
      if (this.finStandaloneService.isLoadingSalesforceConfig) {
        promises.push(taskFor(this.finStandaloneService.loadSalesforceConfig).lastRunning!);
      } else {
        promises.push(taskFor(this.finStandaloneService.loadSalesforceConfig).perform());
      }

      if (this.finStandaloneService.isLoadingSalesforceLiveChatConfig) {
        promises.push(taskFor(this.finStandaloneService.loadSalesforceLiveChatConfig).lastRunning!);
      } else {
        promises.push(taskFor(this.finStandaloneService.loadSalesforceLiveChatConfig).perform());
      }

      if (this.finStandaloneService.isLoadingSalesforceLiveChatSetupData) {
        promises.push(
          taskFor(this.finStandaloneService.loadSalesforceLiveChatSetupData).lastRunning!,
        );
      } else {
        promises.push(taskFor(this.finStandaloneService.loadSalesforceLiveChatSetupData).perform());
      }
    }

    await Promise.all(promises);
  }
}
