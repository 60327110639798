/* RESPONSIBLE TEAM: team-product-exploration  */

import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import {
  routeRedirector,
  SETTINGS_REDIRECT_MAPPING,
  isOnMigratedRoute,
} from 'embercom/lib/settings/settings-redirect-map';
import { hash } from 'rsvp';

export default class SettingsRoute extends Route {
  @service declare router: RouterService;
  @service declare appService: any;
  @service declare store: any;
  @service declare customerService: $TSFixMe;

  analytics = {
    section: 'settings',
  };

  beforeModel(transition: Transition) {
    if (!isOnMigratedRoute(transition.to.name)) {
      routeRedirector(
        transition,
        this.router,
        SETTINGS_REDIRECT_MAPPING,
        'apps.app.settings.index',
      );
    }
  }

  async model() {
    return hash({
      helpCenters: this.store.findAll('help-center-site').catch(() => {}),
      stripeMigration: this.customerService.getStripeMigration(),
    });
  }

  @action
  willTransition(transition: Transition) {
    if (transition.to.name.includes('settings')) {
      window.document
        .querySelector('.main__content-outlet')
        ?.scrollTo({ top: 0, behavior: 'instant' });
    }
  }
}
