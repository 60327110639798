/* RESPONSIBLE TEAM: team-tickets-1 */

import { inject as service } from '@ember/service';
import ModelCacheHelper from 'embercom/lib/model-cache/helper';
import { hash } from 'rsvp';
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import type TeamsController from 'embercom/controllers/apps/app/settings/helpdesk/teams';
import type RouterService from '@ember/routing/router-service';

export default class TeamsRoute extends SettingsRoute {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare modelDataCacheService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare intercomConfirmService: $TSFixMe;
  @service declare router: RouterService;

  analytics = {
    section: 'settings',
    place: 'teams',
  };

  get titleToken(): string {
    return this.intl.t('new-settings.submenu.helpdesk.team-inboxes');
  }

  model() {
    let app = this.modelFor('apps.app') as $TSFixMe;
    if (ModelCacheHelper.isDisabled()) {
      this.store.findRecord('app', app.get('id'));
    } else {
      this.modelDataCacheService.fetch('app', app.get('id'));
    }
    return hash({
      schedules: this.store.findAll('office-hours-schedule'),
      teamSettings: this.store.findAll('team-setting'),
    });
  }

  async beforeModel(transition: Transition) {
    // if current teammate doesn't have access to teams, transition to the new settings home
    if (!this.canManageTeams()) {
      this.permissionsService.cancelTransitionAndShowPermissionModal(
        'can_manage_teams',
        transition,
      );
      this.router.transitionTo('apps.app.settings.index');
    }
    return super.beforeModel(transition);
  }

  canManageTeams() {
    return this.permissionsService.currentAdminCan('can_manage_teams');
  }

  afterModel() {
    let app = this.modelFor('apps.app') as $TSFixMe;
    return this.modelDataCacheService.awaitRefreshedModel('app', app.get('id'));
  }

  _deleteOrRollback(model: $TSFixMe) {
    if (model.get('isNew')) {
      model.deleteRecord();
    } else {
      model.rollbackAttributes();
    }
  }

  _revertTeamsState() {
    let controller = this.controller as TeamsController;
    let dirtyTeams: $TSFixMe = controller.dirtyTeams;
    let dirtyTeamSettings: $TSFixMe = controller.dirtyTeamSettings;

    dirtyTeams.forEach(this._deleteOrRollback);
    dirtyTeamSettings.forEach(this._deleteOrRollback);
  }

  @action
  async error(e: $TSFixMe) {
    if (e.responseJSON && e.responseJSON.test_app) {
      let options = {
        title: this.intl.t('apps.app.settings.teammates.index.switching-to-production'),
        body: this.intl.t('apps.app.settings.teammates.index.switch-to-production-message'),
        confirmButtonText: this.intl.t('apps.app.settings.teammates.index.switch-to-production'),
      };
      let isConfirmed = await this.intercomConfirmService.confirm(options);
      if (!isConfirmed) {
        return;
      }
      let parentAppId = e.responseJSON.parent_app_id;
      window.location = `/a/apps/${parentAppId}/settings/teams/` as unknown as Location;
    }
  }

  @action
  willTransition(transition: Transition) {
    let controller = this.controller as TeamsController;
    if (controller.hasUnsavedChanges()) {
      if (
        window.confirm(this.intl.t('apps.app.settings.teammates.index.unsaved-changes-warning'))
      ) {
        this._revertTeamsState();
      } else {
        transition.abort();
      }
    }
  }
}
