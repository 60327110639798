/* RESPONSIBLE TEAM: team-channels */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import { hash } from 'rsvp';
import type Transition from '@ember/routing/-private/transition';

export default class WhatsappRoute extends SettingsRoute {
  @service declare permissionsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;
  @service declare store: $TSFixMe;

  get titleToken() {
    return this.intl.t('new-settings.channels.whatsapp.title');
  }

  async beforeModel(transition: Transition) {
    await this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_product_settings',
      transition,
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'whatsapp_settings_page',
    });
  }

  async model() {
    return hash({
      appPackage: this.store.findRecord('appstore/app-package', 'whatsapp-fqam'),
      integrations: this.store.findAll('whatsapp/integration'),
      settings: this.store.findRecord('whatsapp/settings', this.appService.app.id),
    });
  }
}
