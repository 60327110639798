/* RESPONSIBLE TEAM: team-ai-agent */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export default class ResolutionBotSetupRoute extends IntercomRoute {
  @service declare router: RouterService;

  redirect() {
    this.router.replaceWith('apps.app.automation.fin-ai-agent.setup');
  }
}
