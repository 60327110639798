/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class StandaloneSettingsRoute extends Route {
  @service declare appService: { app: { canUseStandalone: boolean; id: string } };
  @service declare finStandaloneService: FinStandaloneService;
  @service declare router: RouterService;

  async beforeModel() {
    await this.finStandaloneService.initialize();
  }

  redirect(): any {
    if (!this.appService.app.canUseStandalone) {
      return this.router.transitionTo('apps.app', this.appService.app.id);
    }
  }
}
