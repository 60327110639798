/* RESPONSIBLE TEAM: team-ai-agent */
/* RESPONSIBLE TEAM: team-ml */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router';

export default class AutomationFinAiAgentPlaygroundRoute extends Route {
  @service declare router: RouterService;

  beforeModel() {
    this.router.transitionTo('apps.app.automation.fin-ai-agent.testing');
  }
}
