/* RESPONSIBLE TEAM: team-self-serve */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';

export default class GettingStartedRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare greatGuidanceService: GreatGuidanceService;

  async model() {
    return await this.greatGuidanceService.fetchSteps(this.appService.app.id);
  }
}
