/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { action } from '@ember/object';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import type KnowledgeOverviewController from 'embercom/controllers/apps/app/knowledge-hub/overview';

export default class KnowledgeHubOverviewIndexRoute extends Route {
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare intercomEventService: $TSFixMe;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;

  get app() {
    return this.appService.app;
  }

  async model() {
    // For discovery banner (fin live status badge)
    this.aiAgentSetupService.loadFinWorkflowsCount();
    this.aiAgentSetupService.loadSetupRulesets();

    // For overview page, custom answer counts shouldn't block rendering because it's down on the page
    this.aiAgentSetupService.loadCustomAnswerCounts();
    // Whereas other numbers are shown first time the overview page is rendered
    return this.aiAgentSetupService.loadKnowledgeUsageSummary();
  }

  @action
  didTransition() {
    this.intercomEventService.trackEvent('knowledge-hub-sources-page-visited');
  }

  resetController(controller: KnowledgeOverviewController, isExiting: boolean) {
    if (isExiting) {
      this.knowledgeHubDrawerEditorService.removeEmberStickyQueryParams(controller);
    }
  }
}
