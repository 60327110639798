/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

export default class CompaniesRoute extends Route {
  @service declare store: Store;

  model() {
    let app: any = this.modelFor('apps/app');
    return this.store.findRecord('data-attribute-settings', app.id);
  }
}
