/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { get } from 'embercom/lib/ajax';
import { action } from '@ember/object';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { taskFor } from 'ember-concurrency-ts';

export default class ContentGapsStandaloneRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare finStandaloneService: FinStandaloneService;

  analytics = {
    section: 'standalone',
    place: 'content-gaps',
  };

  async model() {
    let promises = [];
    promises.push(this.aiAgentSetupService.load());
    promises.push(taskFor(this.finStandaloneService.loadZendeskConfig).perform());
    promises.push(taskFor(this.finStandaloneService.loadSalesforceConfig).perform());
    await Promise.all(promises);

    let latestQuestionExtractionRunId = await this.loadLastQuestionGroupsRunDetails();
    let finIsLive = this.finIsLive;
    return {
      latestQuestionExtractionRunId,
      finIsLive,
    };
  }

  async loadLastQuestionGroupsRunDetails(): Promise<$TSFixMe> {
    let response = await get(
      '/ember/ai_agent/question_groups/latest_question_extraction_run_details',
      {
        app_id: this.appService.app.id,
      },
    );
    return response.id;
  }

  @action
  refreshModel() {
    this.refresh();
  }

  get finIsLive(): boolean {
    return (
      this.finStandaloneService.zendeskConfig?.isEnabledForTickets ||
      this.finStandaloneService.zendeskConfig?.sunshineIntegration?.isEnabledForSunshineMessenger ||
      this.finStandaloneService.salesforceConfig?.isEnabled ||
      false
    );
  }
}
