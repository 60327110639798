/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { hideIntercomWidgetWhenLoaded } from 'embercom/lib/intercom-widget-helper';
import type ThemeService from 'embercom/services/theme-service';
import Theme from 'embercom/objects/inbox/types/theme';

export default class AppsAppCopilotRoute extends Route {
  @service declare appService: any;
  @service declare session: Session;
  @service declare themeService: ThemeService;

  async beforeModel() {
    // Never use dark mode in the copilot frame – it does not look right within Zendesk etc
    // Manually set the theme to light and apply it but avoid persisting the theme
    // @ts-expect-error
    this.themeService._theme = Theme.Light;
    // @ts-expect-error
    this.themeService.updateUIWithTheme();

    hideIntercomWidgetWhenLoaded();

    this.session.switchWorkspace(this.appService.app.id);
    await this.session.getTeammate(this.appService.app.id);
  }
}
