/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import RSVP from 'rsvp';

export default class SlaRoute extends ProductSettingsRoute {
  @service store;

  titleToken = 'SLAs';

  analytics = {
    section: 'settings',
    place: 'slas',
  };

  model() {
    return RSVP.hash({
      officeHoursSchedules: this.store.findAll('office-hours-schedule'),
      slas: this.store.findAll('inbox/conversation-sla'),
    });
  }
}
