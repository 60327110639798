/* RESPONSIBLE TEAM: team-phone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router';
import { action } from '@ember/object';

export default class AutomationFinAiAgentVoicePlaygroundRoute extends Route {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.voice-playground',
  };

  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.voice-playground.title');
  }

  beforeModel() {
    if (!this.appService.app.canUseFinVoicePlayground) {
      this.router.transitionTo('apps.app.fin-ai-agent.index');
    }
  }

  async model() {
    return {};
  }

  @action
  didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'voice_playground',
    });
  }
}
