/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import type Store from '@ember-data/store';
import type AutomationNavCounts from 'embercom/services/automation-nav-counts';
import type Transition from '@ember/routing/transition';
import {
  AUTOMATION_REDIRECT_MAPPING,
  routeRedirector,
} from 'embercom/lib/settings/settings-redirect-map';

export default class FinAIAgentRoute extends Route {
  @service declare router: Router;
  @service declare appService: any;
  @service declare outboundHomeService: any;
  @service declare store: Store;
  @service contentImportService: any;
  @service declare automationNavCounts: AutomationNavCounts;

  allAnswersCount: any;
  allProfilesCount: any;

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-ai-agent',
    };
  }

  beforeModel(transition: Transition) {
    if (this.appService.app.canUseStandalone) {
      let transitionToName = transition.to.name;
      if (transitionToName.includes('apps.app.fin-ai-agent.custom-answers')) {
        let params = this.paramsFor('apps.app.automation.fin-ai-agent.custom-answers.answer') as {
          answer_id: string;
        };
        let answerId = params.answer_id;
        let redirectToName = transitionToName.replace(
          'fin-ai-agent.custom-answers',
          'standalone.knowledge.custom-answers',
        );

        if (answerId) {
          this.router.replaceWith(redirectToName, answerId);
        } else {
          this.router.replaceWith(redirectToName);
        }
      }
    } else {
      routeRedirector(
        transition,
        this.router,
        AUTOMATION_REDIRECT_MAPPING,
        'apps.app.automation.fin-ai-agent.setup',
      );
    }
  }

  async loadProfileCounts() {
    // We don't want to fail the page load in case this ever goes wrong
    try {
      let response = await this.outboundHomeService.contentSearch({
        object_types: [objectTypes.resolutionBotBehavior],
        app_id: this.appService.app.id,
      });

      this.allProfilesCount = response.totalCount;
    } catch (e) {
      console.error(e);
    }
  }

  async loadDescriptors() {
    // We don't want to fail the page load in case this ever goes wrong
    try {
      await this.store.findAll('conversation-attributes/descriptor');
    } catch (e) {
      console.error(e);
    }
  }
  async loadCustomObjects() {
    // We don't want to fail the page load in case this ever goes wrong
    try {
      await this.store.findAll('custom-objects/object-type');
    } catch (e) {
      console.error(e);
    }
  }

  async loadInsertableActions() {
    // We don't want to fail the page load in case this ever goes wrong
    try {
      await this.store.findAll('workflow-connector/insertable-action');
    } catch (e) {
      console.error(e);
    }
  }

  async model() {
    await Promise.all([
      this.contentImportService.fetchAiContentLibrarySummary(),
      this.contentImportService.fetchContentReviewSummary(),
      this.contentImportService.fetchFinConversationContentSettings(),
      this.automationNavCounts.loadCustomAnswerCounts(),
      this.loadProfileCounts(),
      this.loadDescriptors(),
      this.loadCustomObjects(),
      this.loadInsertableActions(),
    ]);

    return {
      // allAnswersCount: this.allAnswersCount,
      allProfilesCount: this.allProfilesCount,
    };
  }
}
