/* RESPONSIBLE TEAM: team-standalone */
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { default as BaseChannelRoute } from 'embercom/routes/apps/app/standalone/channels/base-channels';

export default class FinStandaloneSalesforceCasesRoute extends BaseChannelRoute {
  @service declare appService: $TSFixMe;
  @service declare finStandaloneService: FinStandaloneService;

  async beforeModel() {
    return await Promise.all([
      this.finStandaloneService.initialize(),
      this.appService.app.loadEmailDomains(),
    ]);
  }
}
