/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
// For Start Screen Guide
import { valueOrDefault } from '@intercom/pulse/lib/computed-properties';

export default Route.extend({
  appService: service(),
  app: readOnly('appService.app'),
  paywallService: service(),
  queryParams: {
    openPaywall: { replace: true },
  },

  product: null,
  productId: valueOrDefault('product.id', null),
  solution: null,
  solutionId: valueOrDefault('solution.id', null),
  templateName: 'apps/app/guide/start-guide',
  analytics: {
    place: 'start_guide',
  },

  activate() {
    this._super(...arguments);
    let params = this.paramsFor(this.routeName);

    if (params && params.openPaywall) {
      this.paywallService
        .open({ comment: 'Start Screen Guide: Platform index' })
        .catch(this.paywallService.handleError);
    }
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.set('product', this.product);
    controller.set('solution', this.solution);
  },
});
