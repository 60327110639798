/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';
import { setPricingParamsIfEmpty } from 'embercom/lib/purchase/setup';
import IntercomRoute from 'embercom/routes/base/intercom-route';

export default class PlanRoute extends IntercomRoute {
  @service purchaseAnalyticsService;
  @service appService;
  @service gtmService;
  @service media;
  @service permissionsService;
  @service cardlessTrialService;
  @service store;
  @service stripev3;
  @service earlyStageService;
  @service notificationsService;
  @service customerService;
  @service intl;
  @service router;

  get app() {
    return this.appService.app;
  }

  activate() {
    document.body.classList.add('responsive');
  }

  deactivate() {
    document.body.classList.remove('responsive');
  }

  afterModel(model, transition) {
    this.gtmService.setupMarketo(MARKETO_FORM_IDS.addOns);

    if (
      !this.openedFromEarlyStageProgression() &&
      this.app.hasActiveSubscription &&
      !model.isDenied &&
      transition.to.queryParams.cardless_trial !== 'true'
    ) {
      return this.transitionTo('apps.app.billing.summary');
    }
  }

  model() {
    return new Promise((resolve) => this.fetchCheckout.perform(resolve));
  }

  beforeModel(transition) {
    if (this.app.forceEmailVerification) {
      return this.transitionTo('apps.app.teams-checkout.verify');
    }

    if (!this.currentAdminHasBillingPermissions) {
      this.trackPermissionBlockEvent('can_access_billing_settings');
      this.permissionsService.ensurePermissionWhenTransitioning(
        'can_access_billing_settings',
        transition,
      );
    }

    if (!this.currentAdminHasManageTeammatesPermissions) {
      this.trackPermissionBlockEvent('can_manage_teammates');
      this.permissionsService.ensurePermissionWhenTransitioning('can_manage_teammates', transition);
    }
  }

  openedFromEarlyStageProgression() {
    return (
      this.app.canUseNewProgressionExperience &&
      this.customerService.earlyStageGraduation?.pricing5_X_Graduation &&
      !this.customerService.customer.cancellationRequestedOutsideMigration
    );
  }

  trackPermissionBlockEvent(permission) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'blocked',
      object: permission,
      context: 'permissions_denied',
      place: 'plan',
    });
  }

  currentAdminHasManageTeammatesPermissions() {
    return this.permissionsService.currentAdminCan('can_access_billing_settings');
  }

  currentAdminHasBillingPermissions() {
    return this.permissionsService.currentAdminCan('can_access_billing_settings');
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);
    setPricingParamsIfEmpty(controller, transition);
    let teamsController = this.controllerFor('apps.app.teams-checkout');
    teamsController.setProperties({ navStep: 2, editingAddons: true });
    teamsController.setBillingPeriodDurationInMonths(1);
  }

  @action
  didTransition() {
    let controller = this.controllerFor('apps.app.teams-checkout');
    this.purchaseAnalyticsService.trackPageView({
      context: 'usecase_signup_flow',
      object: 'add_ons_page',
      place: 'add_ons',
      planIds: controller.addOnPlanIds,
      solutionId: controller.solutionId,
      cartAmount: controller.solutionPlusAddOnPrice.getMonthlyTotalAfterTrial(
        controller.billingPeriodDurationInMonths,
      ),
      gclid: controller.gclid,
      emailSubmissionId: controller.emailSubmissionId,
      mobile: this.media.isMobile,
      addOn: controller.addOn,
      locale: this.app.currentAdmin.locale,
    });
  }

  @task
  *fetchCheckout(resolve) {
    let checkout = yield this.store.findRecord('checkout', this.app.id, { reload: true });

    if (checkout.isNotReady) {
      // Timeout is managed server-side. It will return status = authorized after some time waiting
      later(this, () => this.fetchCheckout.perform(resolve), ENV.APP._2000MS);
    } else {
      resolve(checkout);
    }
  }
}
