/* RESPONSIBLE TEAM: team-tickets-1 */
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { hash } from 'rsvp';
import type Session from 'embercom/services/session';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';

export default class ConversationTicketsRoute extends BaseSettingsRoute {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare permissionsService: $TSFixMe;
  @service declare store: Store;
  @service declare session: Session;
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('new-settings.submenu.data.conversation-tickets');
  }

  async beforeModel(transition: any) {
    if (
      this.appService.app.canUseStandalone &&
      !this.appService.app.allowAccessOutsideStandaloneSection
    ) {
      this.router.transitionTo('apps.app.settings.standalone.integration');
    }

    super.beforeModel(transition);
    this.session.switchWorkspace(this.appService.app.id);
    await this.session.workspace.fetchRequiredAttributes();

    let permission = 'can_access_product_settings';
    this.permissionsService.ensurePermissionWhenTransitioning(permission, transition);
  }

  async model() {
    return hash({
      reloadAttributeDescriptors: this.reloadAttributeDescriptors,
      descriptors: this.store.query('conversation-attributes/descriptor', {
        system_defined: false,
      }),
    });
  }

  @action
  reloadAttributeDescriptors() {
    this.refresh();
  }
}
