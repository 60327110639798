/* RESPONSIBLE TEAM: team-standalone */
import { default as FinGuidanceRoute } from 'embercom/routes/apps/app/automation/fin-ai-agent/guidance';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router';
import type ContentImportService from 'embercom/services/content-import-service';

export default class GuidanceStandaloneRoute extends FinGuidanceRoute {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare contentImportService: ContentImportService;

  analytics = {
    section: 'standalone',
    place: 'standalone.guidance',
  };

  beforeModel() {
    if (!this.appService.app.canUseFinGuidance) {
      this.router.transitionTo('apps.app.standalone.channels.overview');
    }
  }

  async model() {
    await this.contentImportService.fetchContentIngestionState();
    await this.contentImportService.fetchAiContentLibrarySummary();
    await this.contentImportService.fetchContentReviewSummary();
    await this.contentImportService.fetchFinConversationContentSettings();
    await this.loadDescriptors();
    await this.loadCustomObjects();

    return super.model();
  }

  async loadDescriptors() {
    try {
      await this.store.findAll('conversation-attributes/descriptor');
    } catch (e) {
      console.error(e);
    }
  }

  async loadCustomObjects() {
    try {
      await this.store.findAll('custom-objects/object-type');
    } catch (e) {
      console.error(e);
    }
  }
}
